// responsible for various job content features in edit job content view

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['type', 'scheduleContainer', 'hoursAndPas',
                    'advancedSwitchContainer', 'advancedContainer',
                    'hoursFraction', 'hours', 'minutes', 'pas']

  connect() {
    this._applyPopovers()
    this.prefillHoursAndMinutes() // for override Override Hours and PAs
  }

  _applyPopovers() {
    let popoverTriggerList = [].slice.call(this.element.querySelectorAll('a[rel=popover]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
  }

  // On Call change:
  // Yes: switch Type to Flexible
  // No: switch Type to Scheduled
  toggleOnCall(event) {
    const onCall = event.target.value == 'true' ? true : false
    const scheduled = !onCall

    let typeTarget = this.typeTargets.find(t => t.defaultValue == scheduled.toString())
    typeTarget.checked = true

    this._typeCallback(scheduled)
  }

  toggleType(event) {
    const scheduled = event.params.type == 'scheduled'
    this._typeCallback(scheduled)
  }

  _typeCallback(scheduled) {
    if(scheduled) {
      this._toggleScheduled()
    } else {
      this._toggleFlexible()
    }
  }

  // Hours(PAs) per week is visible when:
  // Type is set to Flexible
  // or
  // Type is set to Scheduled and Advanced is selected and Override Hours and PAs is set to Yes
  _toggleScheduled() {
    this.scheduleContainerTargets.forEach((t) => t.classList.remove('d-none'))
    this.hoursAndPasTarget.classList.add('d-none')
  }

  // hide everything in Schedule fieldset except Type radios
  // hide Summary fieldset
  // show Hours(PAs) per week
  _toggleFlexible() {
    this.scheduleContainerTargets.forEach((t) => t.classList.add('d-none'))
    this.hoursAndPasTarget.classList.remove('d-none')
  }

  // change css class of the fieldset when open/closed Advanced
  toggleAdvanced() {
    // console.log('job-content--edit | toggleAdvanced()')
    this.advancedContainerTarget.classList.toggle('d-none')
    this.advancedSwitchContainerTarget.classList.toggle('advanced-open')
  }

  // used in Override Hours and PAs section to calculated PAs based using entered Hours per Week
  calculateUnscheduledPas(event) {
    event.preventDefault()

    const type = event.params.type
    const hours = this.hoursFractionTarget.value

    if(!hours) {
      this.pasTarget.value = 0
    } else {
      if(type == 'non_premium') {
        this.pasTarget.value = hours / 4
      } else if(type == 'premium') {
        this.pasTarget.value = hours / 3
      }
    }
  }

  // Override Hours and PAs

  // sets hours and minutes number inputs using hours value
  prefillHoursAndMinutes() {
    if(!this.hasHoursAndPasTarget) {
      return
    }

    // only overwite Hours and PAs container visible
    if(this.hoursAndPasTarget.classList.contains('d-none')) {
      return
    }

    if(this.hoursFractionTarget.value > 0) {
      const h = parseInt(this.hoursFractionTarget.value)
      const m = Math.round((this.hoursFractionTarget.value - h) * 60)
      this.hoursTarget.value = h
      this.minutesTarget.value = m
    }
  }

  // callbacks for hours and minutes inputs
  hoursChanged(event) {
    this._calculateAndUpdateHoursFraction()
  }

  minutesChanged(event) {
    this._calculateAndUpdateHoursFraction()
  }

  _calculateAndUpdateHoursFraction() {
    const h = parseInt(this.hoursTarget.value) || 0
    const m = parseInt(this.minutesTarget.value) || 0

    let res = h
    if(m > 0) {
      res = res + (m / 60.0)
    }

    this.hoursFractionTarget.value = res
  }
}
