import { Controller } from '@hotwired/stimulus'

import { Calendar } from '@fullcalendar/core'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import customListViewPlugin from 'custom/fullcalendar/custom_list_view.js'

import moment from 'moment'

// active range - all what we see, like for Aug, 29.07 - 08.09
// current range - what is the subject range, like for Aug, 01.08 - 31.08

export default class extends Controller {
  static values = { id: String,
    activeTab: String,
    activeView: { type: String, default: 'dayGridMonth' },
    print: { type: Boolean, default: false },
    initialId: String,
    initialDate: { type: String, default: null },
    start: String, end: String,
    hiddenStates: Array,
    publicHolidays: Object,
    leaveYearStartMonth: Number, leaveYearEndMonth: Number,
    leavePeriodsUrl: String,
    subjectHasEleave: { type: Boolean, default: false },
    allowancesUrlTemplate: String,
    newLeaveRequestUrl: String,
    editApproversUrl: String }

  connect() {
    this.calendar()

    document.addEventListener('fullcalendar:refetch-events', async () => {
      this.calendar.refetchEvents()
    })
  }

  prepareLeaveYearStart() {
    const currentYear = new Date().getFullYear()
    return new Date(currentYear, this.leaveYearStartMonthValue, 1)
  }

  prepareLeaveYearEnd() {
    const currentYear = new Date().getFullYear()
    const date = new Date(currentYear, this.leaveYearEndMonthValue, 1)
    const mDate = moment(date)
    return new Date(currentYear + 1, this.leaveYearEndMonthValue, mDate.endOf('month').date())
  }

  prepareInitialDate() {
    if(this.activeViewValue == 'customYear') {
      return this.prepareLeaveYearStart()
    } else {
      return this.initialDateValue || this.startValue
    }
  }

  calendar() {
    let url = new URL(this.leavePeriodsUrlValue)

    url.searchParams.set('id', this.initialIdValue)
    url.searchParams.set('active_tab', this.activeTabValue)
    if(this.startValue) url.searchParams.set('start', this.startValue)
    if(this.endValue) url.searchParams.set('end', this.endValue)

    const that = this

    const events = {
      url: url.toString(),
      method: 'GET',
      failure: (err) => console.log('there was an error while fetching events!', err),
      success: (data) => {
        // prepares data used for finding days with crowded leave events
        if(this.activeTabValue != 'user' && this.activeTabValue != 'approvee') {
          const counts = {}

          data.forEach(function(event) {
            const start = event.start.split('T')[0]
            const end = event.end.split('T')[0]

            const state = event.extendedProps.state
            if(state != 'requested' && state != 'approved') {
              return
            }

            const dates = []
            const startMoment = moment(start)
            const endMoment = moment(end)

            while (startMoment.isSameOrBefore(endMoment)) {
              dates.push(startMoment.format('YYYY-MM-DD'))
              startMoment.add(1, 'day')
            }

            dates.forEach((date) => {
              if (!counts[date]) {
                counts[date] = 0
              }
              counts[date] += 1
            })
          })

          this.element.querySelectorAll('.fc-day').forEach((el) => {
            const date = el.getAttribute('data-date')
            const count = counts[date]
            if(count) {
              el.setAttribute('data-overlapping-events-count', count)
            }
          })
        }
        this.dispatch('update-warnings', { detail: { active_tab: this.activeTabValue } })
      }
    }

    const options = {
      plugins: [ bootstrap5Plugin, dayGridPlugin, interactionPlugin, customListViewPlugin ],
      events: events,
      //timeZone: 'UTC',
      themeSystem: 'bootstrap5',
      firstDay: 1,

      initialView: this.activeViewValue,
      initialDate: that.prepareInitialDate(),

      height: 'auto', // Ensure calendar height adjusts to content
      contentHeight: 'auto', // Ensure calendar height adjusts to content

      validRange: {
        end: this.calculateValidRangeEnd()
      },

      views: {
        customMonth: {
          type: 'custom',
          duration: { months: 1 }
        },
        customYear: {
          type: 'custom',
          duration: { months: 12 },
          titleFormat: { year: 'numeric' }
        }
      },

      headerToolbar: that.headerToolbarOpts(),
      customButtons: {
        calendar: {
          text: 'calendar',
          click: () => {
            this.calendar.changeView('dayGridMonth')
          }
        },
        customMonth: {
          text: 'month',
          click: () => {
            document.querySelector(".fc-header-toolbar .fc-calendar-button[title = 'calendar']").classList.remove('active')

            this.calendar.changeView('customMonth')
          }
        },
        customYear: {
          text: 'year',
          click: () => {
            document.querySelector(".fc-header-toolbar .fc-calendar-button[title = 'calendar']").classList.remove('active')

            const start = that.prepareLeaveYearStart()
            const end = that.prepareLeaveYearEnd()

            this.startValue = start.toISOString()
            this.endValue = end.toISOString()

            this.calendar.changeView('customYear', { start: start, end: end })
            this.calendar.gotoDate(start)

            this.calendar.refetchEvents() // for some reason without this user has to click twice
          }
        },
        newRequest: {
          text: 'New Request',
          click: () => {
            const currentStart = new Date(this.calendar.view.currentStart)
            const year = currentStart.getFullYear()
            const month = currentStart.getMonth()

            let todayDate = new Date()
            const todayYear = todayDate.getFullYear()
            const todayMonth = todayDate.getMonth()

            let d = null
            if(todayYear == year && todayMonth == month) { // if current day visible set it to start date of new request
              d = new Date()
            } else { // if not set it to first day of month
              d = new Date(year, month, 1)
            }
            const date = that.calendar.formatIso(d, true).split('T')[0]
            const url = new URL(that.newLeaveRequestUrlValue)
            url.searchParams.set('from_date', date)
            that.renderTurboStreamFromUrl(url.toString(), 'modal', 'replace')
            // after turbo update probably may be replaced with Turbo.visit
          }
        },
        viewAllowances: {
          text: 'View Allowances',
          click: () => {
            let cleanUrl = window.location.href.split('#')[0]
            window.location = `${cleanUrl}#allowances-table`
          }
        },
        editApprovers: {
          text: 'Edit Approvers',
          click: () => that.renderTurboStreamFromUrl(that.editApproversUrlValue, 'modal', 'replace')
        }
      },
      displayEventTime: false,
      eventTextColor: 'black',
      selectable: true,
      buttonIcons: {
        prev: 'chevron-left',
        next: 'chevron-right',
        prevYear: 'chevron-double-left',
        nextYear: 'chevron-double-right'
      },
      dayCellDidMount: (info) => {
        //console.log('dayCellDidMount', info, this)
        const calendar = info.view.calendar
        const el = info.el
        const date = calendar.formatIso(info.date, true)

        // set public holidays for day cells
        if(Object.keys(this.publicHolidaysValue).includes(date)) {
          const title = this.publicHolidaysValue[date]
          // add public holiday label
          const span = document.createElement('span')
          span.setAttribute('class', 'public_holiday_label')
          span.innerHTML = title

          el.appendChild(span)
        }
      },

      // triggered once
      viewDidMount: (info) => {
        const start = info.view.activeStart
        const end = info.view.activeEnd

        that.startValue = start.toISOString()
        that.endValue = end.toISOString()

        if(!this.printValue) {
          document.querySelector(".fc-header-toolbar .fc-calendar-button[title = 'calendar']").classList.add('active')
        }

        // attribute responsible for showing plus icon on day cell hover
        if(this.newLeaveRequestUrlValue && !this.printValue) {
          this.element.setAttribute('data-can-add-leave-periods', 'true')
        } else {
          this.element.removeAttribute('data-can-add-leave-periods')
        }
      },

      eventDidMount: (info) => {
        const el = info.el
        const props = info.event.extendedProps

        const state = props.state.toLowerCase().replace(/\s+/g, '_')
        const accrue = props.type == 'Accrue'

        el.setAttribute('data-state', state)
        if(accrue) {
          el.setAttribute('data-accrue', accrue)
        }
        el.setAttribute('data-turbo', 'true')
        el.setAttribute('data-turbo-frame', 'modal')

        if(that.hiddenStatesValue.includes(state) || (accrue && that.hiddenStatesValue.includes('accrual'))) {
          el.style.display = 'none'
        }
      },

      selectAllow: () => {
        return !!this.newLeaveRequestUrlValue
      },

      select: (info) => {
        let url = new URL(that.newLeaveRequestUrlValue)
        url.searchParams.set('from_date', info.startStr)

        if(info.endStr) {
          let mEnd = moment(info.endStr).subtract(1, 'days')
          let fEnd = mEnd.format('YYYY-MM-DD')

          url.searchParams.set('until_date', fEnd)
        }
        that.renderTurboStreamFromUrl(url.toString(), 'modal', 'replace')
      },

      datesSet: (info) => {
        const monthStr = `${info.view.currentStart.getFullYear()}-${(info.view.currentStart.getMonth() + 1).toString().padStart(2, '0')}-01`

        const detail = { view: info.view.type, start: info.view.currentStart, end: info.view.currentEnd, monthStr: monthStr }
        that.dispatch('range-changed', { detail: detail })

        this.renderAllowances(info)
      }
    }
    this.calendar = new Calendar(this.element, options)
    this.calendar.render()
  }

  // triggered from leave request form controller
  reloadCallback(_event) {
    this.calendar.refetchEvents()
  }

  hiddenStatesChangedCallback(event) {
    this.hiddenStatesValue = event.detail.value
  }

  hiddenStatesValueChanged() {
    this.element.querySelectorAll('.fc-event').forEach((el) => {
      const state = el.getAttribute('data-state')
      const accrue = el.getAttribute('data-accrue')

      if(this.hiddenStatesValue.includes(state)) {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }

      if(this.hiddenStatesValue.includes('accrual') && accrue) {
        el.style.display = 'none'
      }
    })
  }

  subjectSelectedChangedCallback(event) {
    const subjectSelected = event.detail.value

    if(!subjectSelected) {
      this.element.classList.add('d-none')
    }
  }

  startValueChanged() {
    this.dispatch('start-changed', { detail: { value: this.startValue } })
  }

  endValueChanged() {
    this.dispatch('end-changed', { detail: { value: this.endValue } })
  }

  renderAllowances(_info) {
    if(!this.subjectHasEleaveValue || !this.initialIdValue) {
      this.dispatch('clear-allowances')
      return
    }

    const allowancesUrl = this.allowancesUrlTemplateValue.replace(/USER_ID/g, this.initialIdValue)

    let mStart = moment(this.calendar.view.currentStart)
    let fStart = mStart.format()

    const mEnd = moment(this.calendar.view.currentEnd).subtract(1, 'days').endOf('day')
    const fEnd = mEnd.format()

    let url = new URL(allowancesUrl)

    url.searchParams.set('start', fStart)
    url.searchParams.set('end', fEnd)

    this.renderTurboStreamFromUrl(url, 'allowances', 'update')
  }

  calculateValidRangeEnd() {
    const today = new Date()
    const year = today.getFullYear() + 2
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    return `${year}-${month}-01`
  }

  headerToolbarOpts() {
    if(this.printValue) {
      return false
    }

    let headerToolbarLeft = []

    if(this.newLeaveRequestUrlValue) {
      headerToolbarLeft.push('newRequest')
    }
    if(this.activeTabValue == 'user' || this.activeTabValue == 'approvee') {
      headerToolbarLeft.push('viewAllowances')
    }
    if(this.editApproversUrlValue) {
      headerToolbarLeft.push('editApprovers')
    }

    return {
      left: headerToolbarLeft.join(','),
      center: 'title',
      right: 'today prev,next prevYear,nextYear calendar,customMonth,customYear'
    }
  }

  renderTurboStreamFromUrl(url, target, action = 'replace', accept = 'text/html') {
    fetch(url, {
      headers: {
        Accept: accept
      }
    })
    .then(r => r.text())
    .then(html => {
      html = `<turbo-stream action="${action}" target="${target}"><template>${html}</template></turbo-stream>`
      Turbo.renderStreamMessage(html)
    })
  }
}
